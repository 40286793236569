import SellersTable from "./reducers/SellersTableReducer";
import SellersSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import IConfig from "interfaces/IConfig";

export const moduleName = "Sellers";
export const url = "/sellers";

const getConfig = (permissions: permissionsType) => {
    const config: IConfig = {
        active: false,
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (permissions["sellers.read"]) {
        config.active = true;

        store.reducerManager?.add("SellersTable", SellersTable);
        store.injectSaga?.("SellersSaga", SellersSaga);

        routing.push({
            path: url,
            element: <AsyncComponent path="Sellers/pages/SellersIndexPage" />,
        });
    }

    if (permissions["sellers.create"]) {
        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Sellers/pages/SellersCreatePage" />,
        });
    }

    if (permissions["sellers.update"]) {
        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Sellers/pages/SellersEditPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
