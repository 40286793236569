import {
    Box,
    Paper,
    Table,
    TableContainer,
    TableCellProps,
    TableRowProps,
    TableProps,
    TableContainerProps,
} from "@mui/material";
import { ReactNode, useEffect, useRef, useState } from "react";

import TableBody from "./TableBody";
import TableHead from "./TableHead";
import TableNoData from "./TableNoData";
import TablePagination from "./TablePagination";
import { TableLoader } from "ui";

export type IGPTableSortDirections = "desc" | "asc";

export interface IGPTableSort {
    [key: string]: IGPTableSortDirections;
}
export interface IGPTablePagination {
    isLastPage: boolean;
    page: number;
    per_page: number;
    total: number;
}
export interface IGPTable {
    availableColumns?: IGPTableColumn[];
    calculateWidth?: boolean;
    columns: IGPTableColumn[];
    filters?: object;
    filterData?: any;
    filterSettings?: ITableFilterSettings;
    header?: React.ReactNode;
    id: string;
    loading?: boolean;
    onClickRow?: (event: React.MouseEvent, item: any) => void;
    organizeColumns?: IGPTableColumn[];
    pagination?: IGPTablePagination;
    renderFooter?: Function;
    renderRow?: (
        item: any,
        idx: number,
        content: TableRowProps,
        columns: IGPTableColumn[]
    ) => React.ReactNode;
    rows?: any;
    search?: string;
    selection?: IGPTableSelection;
    selectionActions?: ReactNode;
    sort?: IGPTableSort;
    reload?: number;
    rowHover?: boolean;
    tableProps?: TableProps;
    tableUpdate?: Function;
    with?: string[];
    withCount?: string[];
    tableContainerProps?: TableContainerProps;
}
export interface IGPTableColumn {
    cellProps?: TableCellProps;
    field: string;
    headName?: string | React.ReactElement;
    onChange?: Function;
    renderCell?: (item: any, key: any, idx: number) => any;
    renderHead?: (item: any) => any;
    initSortDirection?: IGPTableSortDirections;
    sortable?: boolean;
    width?: number;
}

export interface IGPTableSelection {
    id: string;
    selected: [];
}

export interface ITableFilterSettings {
    open: boolean;
    total: number;
}

function GuiTable(props: IGPTable) {
    const tableRef = useRef<HTMLElement>();
    const [width, setWidth] = useState(props.calculateWidth ? 0 : "100%");

    const renderFooter = () => {
        if (!!props.renderFooter) {
            return props.renderFooter();
        }
    };

    useEffect(() => {
        function resizeTable() {
            setWidth(0);

            setTimeout(() => {
                const element = tableRef.current?.parentElement as Element;
                if (element) {
                    let styles = getComputedStyle(element);

                    setWidth(
                        element.clientWidth -
                            parseFloat(styles.paddingLeft) -
                            parseFloat(styles.paddingRight)
                    );
                }
            }, 200);
        }

        if (props.calculateWidth) {
            resizeTable();
            window.addEventListener("resize", resizeTable);
        }

        return () => window.removeEventListener("resize", resizeTable);
    }, [props.calculateWidth]);

    let rowsLength = !!props.rows ? props.rows.length : 0;

    return (
        <Box ref={tableRef} sx={{ maxWidth: "100%", width }}>
            <Paper
                sx={{ width: "100%", overflow: "hidden", position: "relative" }}
            >
                {props.header}
                <TableLoader {...props} />

                <TableContainer {...props.tableContainerProps}>
                    <Table
                        id={props.id}
                        stickyHeader
                        {...props.tableProps}
                        sx={{
                            "& .MuiTableRow-hover:hover td": {
                                backgroundColor: "#ebebeb",
                            },
                            "& .MuiTableCell-root": {
                                px: { xs: 1, sm: 2 },
                            },
                            ...props.tableProps?.sx,
                        }}
                    >
                        <TableHead {...props} />
                        <TableBody {...props} />
                        {renderFooter()}
                    </Table>
                </TableContainer>

                <TableNoData rows={rowsLength} />
            </Paper>

            {!!props.pagination && (
                <TablePagination
                    rows={rowsLength}
                    pagination={props.pagination}
                    tableUpdate={props.tableUpdate}
                />
            )}
        </Box>
    );
}

GuiTable.defaultProps = {
    calculateWidth: false,
    header: null,
    tableProps: {},
};

export default GuiTable;
